import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import { Column, Container, Row } from '../../components/Grid';
import Video from '../../components/Video';

const MasterClass = () => {
  return (
    <Layout title="Master Class">
      <Section>
        <Container fullWidth>
          <Row>
            <h2>Master Class</h2>
            <p>
              The Outside Resources Master Class provides a model for educators
              looking to expand lessons beyond the classroom. Experienced fellow
              educators who have used outside resources demonstrate just how
              effective they can be in the classroom. Lessons come alive,
              connections between theory and real-world application become
              clear, and students more actively engage with the material.
            </p>
          </Row>
          <Row>
            <Column offset={1} size={10}>
              <Video
                guidSrc="1d60cc84-ae8b-434b-9340-7e5c9df6a454"
                className="video-player"
                title="Master Class"
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row>
            <Column size={8}>
              <h2>Master Class: Outside Resources</h2>
              <p>
                In this Master Class Video, you will hear from two classroom
                educators leveraging outside resources to inspire and engage
                their students. Utilize this video with the Master Class
                Educator Guide and other Sustainable Futures resources.
              </p>
              <Button
                to="/pdfs/SustainableFutures-Masterclass-EdGuide-V1.0.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Educator Guide
                <Icon name="arrowright" marginLeft />
              </Button>
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default MasterClass;
